.vote-column-container {
  margin: 8px;
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 40%;
}

.vote-column-title {
  padding: 8px;
}
