.primary.container {
    width: auto;
    height: auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: none;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}





/* TOP ROW */
.container.planet.row.top {
    grid-row: 1;
    display: grid;
    grid-template-rows: none;
    grid-template-columns: 1fr;
    text-align: center;
}






/* CENTER ROW */
.container.planet.row.center {
    grid-row: 2;
    display: grid;
    grid-template-rows: none;
    grid-template-columns: 1fr 2fr 1fr;
    text-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.container.planet.column.center-left {
    text-align: right;
    align-content: right;
    justify-content: right;
    align-self: right;
    justify-self: right;
    border-top-right-radius: -20%;
}

.container.planet.column.center-center {
    border-radius: 50%;
    height: 300px;
    width: 300px;
    justify-self: center;
    align-self: center;
    align-content: center;
}

.planet-image {

}

.container.planet.column.center-right {
    text-align: left;
    align-content: center;
}








/* BOTTOM ROW */
.container.planet.row.bottom {
    grid-row: 3;
    display: grid;
    justify-content: center;
    align-content: center;
}

.container.planet.column.bottom-center {
    grid-column: 1;
    text-justify: center;
    text-align: center;
}
















/* .primary.container {
    min-height: 400px;
    max-height: 600px;
    min-width: 400px;
    max-width: 600px;
    aspect-ratio: 1/1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    "heading heading heading"
    "stats-left planet-image stats-right"
    "footer footer footer";
    justify-content: space-around;
    align-content: center;
    overflow: hidden;
    border: thin dotted white;
}

.primary-container > row {

}

.primary.target-heading {
    grid-area: heading;
}

.primary.planet-image {
    grid-area: planet-image;
    min-height: 50%;
    max-height: 100%;
    min-width: 50%;
    max-width: 100%;
    aspect-ratio: 1/1;
    margin: 1vw;
    justify-self: center;
    align-self: center;
}

.primary.left {
    grid-area: stats-left;
    border: thin dotted pink;
    margin: auto;
}

.primary.right {
    grid-area: stats-right;
    border: thin dotted orange;
    margin: auto;
}

.primary.target-footer {
    grid-area: footer;
    border: thin dotted cyan;
    margin: auto;
} */
