/* VoteContainer.css */
.vote-container {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.voting-box{
  text-align: center;
}


.voting-box button {
  font-weight: bold;
  color: rgb(218, 218, 32);
  background-color: #1a1b22;
  width: 150px;
  margin-top: 50px;
  margin-bottom: 50px;
  padding-top: 5px;
  padding-bottom: 5px;
}