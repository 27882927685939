/* .login-page {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.login-page form{

  display: inline-block;
  flex-direction: column;
  align-content: space-around;
  padding-top: 80px;
}
.login-page .span-holder {
  padding-top: 75px;
  padding-bottom: 25px;
}

.login-page form label {
  display: inline-flex;
  font-size: medium;
  margin-bottom: 5px;
  font-weight: bold;
}

.login-page form input {
  font-size: large;
  padding: 10px;
}

.login-page form span {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  margin: 10px;
}

.login-page button {
  position: relative;
  color: black;
  background-color: orange;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  border-radius: 1px solid black;
  font-size: larger;
  width: 250px;
  cursor: pointer;
  left: 10px;
}

.login-page h1 {
  margin-bottom: 0px;
  position: relative;
  top: 100px;
}

.login-page .login-errors {
  color: red;
} */


/* body {
  height: 100vh;
  align-items: center;
}

.login-page {
  display: grid;
  height: 100vh;
  grid-template-rows: auto;
  grid-template-columns: auto;
  justify-content: center;
}

.login-page h1 {
  display: grid;
  grid-row: 1;
  align-content:end;
  justify-content: center;
}

.login-page form {
  display: grid;
  grid-template-columns: min-content;
  grid-row: 2;
  align-content: start;
  justify-content: center;
  justify-items: center;
}

.login-page form > * {
  margin: 15px;
}

.login-page button {
  display: grid;
  grid-row: 3;
  justify-content: center;
  align-items: start;
  width: 100%;
  height: min-content;
} */

.login-page {
  display: flex;
  font-weight: bold;
  height: 90vh;
  align-items: center;
  justify-content: center;
}

.login-page h3 {
  font-size: 10px;
  margin: 0;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  scale: 1.5;
}

.form-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.form-container .span-holder {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-container form button {
  margin-top: 20px;
}

.form-container button {
  font-weight: bold;
  color: rgb(218, 218, 32);
  background-color: #1a1b22;
  width: 150px;
  padding-top: 5px;
  padding-bottom: 5px;
}
