* {
/* border: thin dotted rgb(141, 141, 141); */
}

body {
  padding: 0;
  margin: 0;
}

.target-container {
  width: calc(100vw - (100vw - 100%));
  height: 100vh-1px;
  display: grid;
  grid-template-columns: 1fr 1.4fr 1fr;
  justify-items: center;
  align-items: center;
  box-sizing:border-box;
  overflow: hidden;
}


.target-heading {
  text-align: center;
}

.stats {
  line-height: .5em;
}

.left {
  text-align: right;
  text-justify: center;
}

.right {
  text-align: left;
  justify-items: center;
}

.target-footer {
  text-align: center;
}







.planet-image {
  height: 80%;
  width: 80%;
  text-justify: center;
  text-align: center;
  justify-content: center;
  align-content: center;
  margin: auto;
  aspect-ratio: 1/1;
  background: url('../../images/planets/196.jpg');
  position: relative;
  animation: rotate 30s linear infinite;
  border-radius: 50%;
  background-repeat: repeat;
  background-size: 200% 100%;
  z-index: -1;
  box-shadow:
  inset -1.5vw -1.5vw 1vw #000,
    1vw 1vw 1vw #000,
    inset .5vw .5vw 1vw #ffedc859,
    -.5vw -.5vw 1vw -.5vw #ffedc8;
}

@keyframes rotate {
  to{ background-position: -200% 0;};
}









/* Old Code Below */
/* ========================================= */

/* Text */
/* div.Automaton {
    color: red;
  }

div.Terminids {
    color:orange;
  }
div.title {
  border: thin dotted pink;
  font-size: 24px;
  font-weight: bold;
  line-height: 0em;
}
#primary-target-title {
  font-size:40px;
  line-height: 0em;
}
#primary-planet-name {
  font-size: 40px;
  line-height: 0em;
}
.planet-liberation {
  font-size: 24px;
  line-height: .5em;
}
.planet-control {

}


.target-container {
  width: 100%;
  height:90vh;
  border: 1px solid black;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  position: relative;
  align-items: center;
  justify-content: center;
}

.bot-container {
  display: grid;
}

.bug-container {
  display: grid;
}

.planet-container {
  position: relative;
  display: grid;
  grid-template-rows: 0fr 0fr;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.planet-container-top {
  display: grid;
  align-self: center;
  justify-self: center;
  text-align: center;
}

.planet-container-center {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

.planet-container-left {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: right;
  margin-right: 15px;
}

.planet-container-right {
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 15px;
}

.planet-container-bottom{
  text-align: center;
  font-size: 24px;
  padding-top: 20px;
} */

/* .planet-liberation-progress-bar {
  width: 500px;
  height: 1em;
  background-color: rgb(0, 0, 0);
  border-radius: 1.5em;
  color: white;
  position: relative;
}

.planet-liberation-progress-bar::before {
  content: attr(data-label);
  display: flex;
  align-items: center;
  position: absolute;
  top: .1em;
  left: .1em;
  bottom: .1em;
  width: 200%;
  min-width: 50%;
  max-width: calc(100% - 3em);
  background-color: #069;
  border-radius: 1em;
  padding: 1em;
} */



/* Planet Names */
/* .planet-name {
  font-weight: bold;
}

.planet-name.main {
  font-size: 20px;
}

.planet-name.primary {
  font-size: 16px;
}

.planet-name.secondary {
  font-size: 12px;
} */



/* Planet Images (temporary) */
/* .planet-image {
  height: 200px;
  width: 200px;
  box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.75);
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  shape-outside: circle(50%);
} */

/* .planet-image {
  width: 20em;
  height: 20em;
  background: url('../../images/planets/196.jpg');
  position: relative;
  animation: rotate 30s linear infinite;
  border-radius: 50%;
  background-repeat: repeat;
  background-size: 200% 100%;
  z-index: -1;
  justify-content: center;
  align-items: center;
  display: flex;
}

@keyframes rotate {
  to{ background-position: -200% 0;};
}


.planet-image.Automaton {
  background-color: red;
  box-shadow:
  inset 1.5em -1.5em 1em #000,
  -1em 1em 1em #000,
  inset 0em 0em 2em #ffedc8,
  .5em -.5em 1em -.5em #ffedc8;
}

.planet-image.Terminids {
  background-color: orange;
  box-shadow:
  inset -1.5em -1.5em 1em #000,
  1em 1em 1em #000,
  inset 0em 0em 2em #ffedc8,
  -.5em -.5em 1em -.5em #ffedc8;
}

.planet-image.main {
  height: 300px;
  width: 300px;
}

.planet-image.primary {
  height: 150px;
  width: 150px;
}

.planet-image.secondary {
  height: 100px;
  width: 100px;
} */
