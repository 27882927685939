
/* TODO Add site-wide styles */

.navbar {
    display: flex;
    justify-content: space-between;
}

.nav-right{
    display: flex;
    justify-content: flex-end;
}

.navbar button {
    width: 150px;
    height: 35px;
    background-color: #1a1b22;
    color: rgb(218, 218, 32);
    font-weight: bold;
    font-size: 20px;
}

* {
    text-shadow: 2px 2px 5px rgb(0, 0, 0);
    font-family:'Courier New', Courier, monospace;
}

body {
    background-color: #1a1b22;
    color: rgb(218, 218, 32);
}

button {
    cursor: pointer;
}

/* p {
    font-size: 16px;
}

h1 {
    font-size: 26px;
}

h2 {
    font-size: 22px;
} */
